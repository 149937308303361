import React from 'react'
import { Container } from 'react-bootstrap'
import "./CompareInvestment.scss"

const {
    ContentModule,
  } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const CompareInvestment = ({item}) => {
    const desc = item?.description?.data?.description
  return (
    <section className='compare-investment-wrapper'>
        <Container>
            <div className='compare-investment'>
                <h2>{item?.title}</h2>
                {desc&&
                <p className="description">
                    <ContentModule
                        Content={desc}
                    /> 
                </p>
                }
                <div className='items-container'>
                {item?.columns.map(item=>{
                    return(
                        <div>
                            <p>{item.column1}</p>
                            <p>{item.column2}</p>
                            <p>{item.column3}</p>
                        </div>
                    )
                })}
                </div>
            </div>
        </Container>
    </section>
  )
}

export default CompareInvestment