import React from 'react'
import CustomSlider from "../CustomSlider/CustomSlider"
import Rating from "../Rating/Rating"
import dateFormat from "dateformat"
import { Container } from "react-bootstrap"
import "./TeamReview.scss"
import { reviewUrl } from "../../lib/urls"
import { Link } from "gatsby"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const {
    ContentModule,
  } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const TeamReview = ({reviewData,staticData,revieweeData, isTeamLanding,moduleData}) => {
    const {isMobile} = useDeviceMedia()

  const removeLowStar=reviewData?.filter(item=>{
    if(item.node.star_rating==="5" || item.node.star_rating==="4"){
      return item
    }
  })

  const sortdData = removeLowStar?.sort((a,b)=>{
    if (a.node.review_date && b.node.review_date) {
      const dateA = new Date(a.node?.review_date);
      const dateB = new Date(b.node?.review_date);
      return dateB - dateA;
      }
      // If one of the dates is falsy or null, prioritize the truthy date
      else if (a.node?.review_date) {
          return -1; // a should come before b
      } else if (b.node?.review_date) {
          return 1; // b should come before a
      } else {
          return 0; // if both dates are null or falsy, maintain the current order
      }

  }
  )

    const filterMemberReview=isTeamLanding ? sortdData :sortdData?.filter(item=>item.node?.email?.trim()===revieweeData?.revieweeEmail)
    const data=filterMemberReview?.slice(0,6)
    if(!filterMemberReview?.length>0) return
    
  return (
    <div className={`reviewee-component-wrapper ${isTeamLanding?"team-land":''}`}>
     <Container>
        <div className="team-review-module">
            <div className="heading-review-team">
                <h2>{staticData?.team_review_heading || moduleData?.title}</h2>
                <p className="description-team-review">
                <ContentModule
                    Content={staticData?.team_review_desc?.data?.team_review_desc || 
                        moduleData?.content?.data?.content}
                /> 
               <Link state={{revieweeData:revieweeData}}  to={reviewUrl} className="more-review-wrapper"><span className="more-reviews">More Reviews</span> <i className="icon arrow-right-icon"/></Link>
                </p>
            </div>
            <CustomSlider
                className="reviewee-slider"
                slidecount={isMobile?1:3}
                showTwo
                showArrows
                noDots={isTeamLanding ? true :false}
                arrowOnMobile={isTeamLanding ? true : false}
            >
                {data?.map(({ node, i }) => {
                    return(
                        <div class="item">
                            <h5>{node?.client_name}</h5> 
                            {node?.review_date&&
                                <p className="review-createdat">{dateFormat(node?.review_date,"d mmmm yyyy")}</p>
                            }
                             <Rating starRating={node?.star_rating} />
                             <p className="description-review">
                             <ContentModule
                                Content={
                                    node?.review &&
                                    node?.review > 220
                                    ? node?.review.substring(0, 220) + "... "
                                    : node?.review
                                }
                            />
                            </p>

                        </div>
                    )

                })}
            </CustomSlider>
            
        </div>


     </Container>
    </div>
  )
}

export default TeamReview