import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SecondaryHeader from "../components/SecondaryHeader/SecondaryHeader"
import CampaignBanner from "../components/CampaignBanner/CampaignBanner"
import CampignWithIcon from "../components/CampignWithIcon/CampignWithIcon"
import CampaignColBlock from "../components/CampaignColBlock/CampaignColBlock"
import CampaignProperties from "../components/CampaignProperties/CampaignProperties"
import CompareInvestment from "../components/CompareInvestment/CompareInvestment"
import ContactDetails from "../components/ContactDetails/ContactDetails"
import qs from "qs"
import logoBlack from "../images/logo-white.svg"
import { removeDataAttributes } from "../components/Common/utils"
import TeamReview from "../components/TeamReview/TeamReview"

const CampaignNewTemplate = ({ data,pageContext }) => {
  const [pageData, setPageData] = useState(data?.strapiCampaignNew)
  const windowSearch= typeof window!=="undefined"?window.location.search:""
  const [loading, setLoading] = useState(() => {
    const checkurlParams = new URLSearchParams(windowSearch);
    const checkisPreview = checkurlParams.get('debug') === 'preview';
    return checkisPreview;
  });
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)
  
  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false) // since its string
  
  const campaignData = pageData
  console.log(pageData,'pageData')
  const reviewList = data?.allStrapiAdminDynamicReview?.edges;
  console.log(reviewList,'reviewList')

  //logic to get property items

  let getIdList = null
  if (campaignData?.add_modules?.length > 0) {
    const results = campaignData?.add_modules?.filter(
      item =>
        (item.strapi_component === "page-modules.custom-modules" ||
          item?.__component === "page-modules.custom-modules") &&
        item?.select_module === "offplan_property_slider"
    )
    if (results?.length) {
      getIdList = results[0]?.propety_ids?.split(",")
    }
  }

  const filterOffplanItems = data?.allNewdevelopments?.edges
  const offplanPropertyList = filterOffplanItems?.filter(item => {
    return getIdList?.some(data => data === item?.node?.crm_id)
  })
  //-----------

   const query = qs.stringify(
      {
        populate: {
          ggfx_results:"*",
          banner_image: "*",
          seo: "*",
          logo: "*",
          mobile_image:"*",
          contact_info: "*",
          form_data: "*",
          add_page_modules: {
            on: {
              'page-modules.image-content': {
                populate: {
                  left_right_block: {
                    populate:{
                    image:"*",
                    menu:{
                      populate:{
                        parent:"*"
                      }
                    }
                  }
                }
                },
              },
              "page-modules.icon-content":{
                populate:{
                  icon_module:{
                    populate:{
                      image:"*"
                    }
                  }
                }
              },
              "page-modules.column-items":{
                populate:{
                  columns:"*"
                }
              },
              "page-modules.custom-module":{
                populate:{
                  select_module:"*"
                }
              }
            },
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );

  useEffect(()=>{
      const urlParams = new URLSearchParams(window.location.search);
      const isPreview = urlParams.get('debug') === 'preview';
      const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/campaign-news/${urlParams.get('strapi_id')}?${query}`
      const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;
  
      if(isPreview){
        setIsPreviewEnabled(true)
      const fetchData = async () => {
        try {
          const response = await fetch(apiUrl, {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          });
          const getData = await response.json();
          const previewData=removeDataAttributes(getData)
          setLoading(false)
          setEnablePreview(false)
          let myPrevData;
          if(pageData){
            myPrevData = pageData
          } else {
            myPrevData = {};
            myPrevData.banner_image = {}
            myPrevData.logo = {}
            myPrevData.thankyou_image = {}
            myPrevData.add_modules=[]
            myPrevData.id = urlParams.get('strapi_id')
          }
  
          if(previewData){
            myPrevData.banner_image = previewData?.banner_image
            myPrevData.logo = previewData?.logo
            myPrevData.banner_content = previewData?.banner_content
            myPrevData.slug = previewData?.slug
            myPrevData.contact_info = previewData?.contact_info
            myPrevData.form_data = previewData?.form_data
            myPrevData.title = previewData?.title
            myPrevData.whatsapp_text = previewData?.whatsapp_text
            myPrevData.custom_form = previewData?.custom_form
            myPrevData.add_modules=previewData?.add_modules
            setPageData(pageData => ({ ...pageData, myPrevData }));
            sessionStorage.setItem("previewMeta",JSON.stringify(previewData?.seo))
          }
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
  
      };
      fetchData();
      }
      else{
        setLoading(false)
        setIsPreviewEnabled(false)
        sessionStorage.removeItem("previewMeta")
      }
  
    },[])
  
      if(loading){
        return (
          <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
              <div id="loader" class="new-loader">
                <img
                  className="logo-white loader-logo"
                  src={logoBlack}
                  alt="logo"
                />
              </div>
            </div>
          </section>
        )
      }
      if(enablePreview){
        return null
      }
  

  return (
    <Layout isCampaign>
      <SecondaryHeader
        whatsapp_num={campaignData?.whatsapp_no}
        whatsapp_text={campaignData?.whatsapp_text}
      />
      <CampaignBanner isPreviewEnabled={isPreviewEnabled} isNewCampaign campaignData={campaignData} />
      {campaignData?.add_modules.map(item => {
        return (
          <div>
            {item?.strapi_component === "page-modules.icon-content" && (
              <CampignWithIcon
                icon_module={item?.icon_module}
                {...item}
                campaign={true}
              />
            )}

            {item?.strapi_component === "page-modules.image-content" &&
              item?.left_right_block?.length > 0 && (
                <CampaignColBlock
                  campaignData={campaignData}
                  id={campaignData?.strapi_id}
                  {...item}
                  campaign={true}
                  isWhitebg
                />
              )}

            {item?.strapi_component === "page-modules.custom-modules" &&
              item?.select_module === "offplan_property_slider" &&
              offplanPropertyList?.length > 0 && (
                <CampaignProperties
                  propertyData={offplanPropertyList}
                  campaignData={campaignData}
                  title={item?.title}
                  content={item?.content}
                  isNewCampaign
                />
              )}
              {(item?.strapi_component ===
              "page-modules.custom-modules" ||
              item?.__component ==="page-modules.custom-modules")
              &&
              item?.select_module === "review" && (
                reviewList?.length>0&&
                  <TeamReview
                  moduleData={item}
                  reviewData={reviewList}
                  isTeamLanding  //since to apply styles
                />                
              )}
              {
                item?.strapi_component === "page-modules.column-items" &&
                <CompareInvestment item={item} pageData={campaignData}/>
              }
          </div>
        )
      })}
      <ContactDetails
        data={campaignData}
        type="campaign"
        whatsapp_text={campaignData?.whatsapp_text}
        isNewCampaign
      />
    </Layout>
  )
}

export const query = graphql`
  query ($page_id: Int) {
    strapiCampaignNew(strapi_id: { eq: $page_id }) {
      ...CampaignFragmentNew
    }
    allStrapiAdminDynamicReview(filter: { department: { in: ["Off Plan", "Off Plan "] } }) {
      edges {
        node {
          broker
          client_name
          review
          review_date
          star_rating
          department
          email
          strapi_id
        }
      }
    }
    allNewdevelopments(filter: { publish: { eq: true } }) {
      edges {
        node {
          images {
            url
          }

          slug
          id
          ggfx_results {
            src_cftle
            transforms {
              url
              transform
              format
            }
          }
          project_title
          crm_id
          development_type
          developer
          strapi_id
          area
          search_type
        }
      }
    }
  }
`
export default CampaignNewTemplate